import logo from '@/assets/images/logo.png'
import logoDark from '@/assets/images/Trazable_Logo_W.png'
import { PAGES } from '@/routes'
import { Menu, Transition } from '@headlessui/react'
import { Fragment, SVGProps, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GlobeIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { languages } from '@/i18n'
import { changePathLanguage, getTranslatedPath } from '@/i18n/utils'
import classNames from 'classnames'
import { useLocation } from '@reach/router'
import { Config } from '@/config'

export const Footer = ({ dark }: { dark?: boolean }): JSX.Element => {
  const { t } = useTranslation('nsLayout')
  const location = useLocation()

  const social = useMemo(
    () => [
      {
        name: t('footer.social.linedin'),
        href: PAGES.EXTERNAL.LINKEDIN,
        icon: (props: SVGProps<SVGSVGElement>) => (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 24 24'
            {...props}
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 8c0 .557-.447 1.008-1 1.008s-1-.45-1-1.008c0-.557.447-1.008 1-1.008s1 .452 1 1.008zm0 2h-2v6h2v-6zm3 0h-2v6h2v-2.861c0-1.722 2.002-1.881 2.002 0v2.861h1.998v-3.359c0-3.284-3.128-3.164-4-1.548v-1.093z'
            />
          </svg>
        ),
      },
      {
        name: t('footer.social.telegram'),
        href: t('footer.social.telegramLink'),
        icon: (props: SVGProps<SVGSVGElement>) => (
          <svg
            aria-hidden='true'
            focusable='false'
            data-prefix='fab'
            data-icon='telegram-plane'
            {...props}
            role='img'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 448 512'
          >
            <path
              fill='currentColor'
              d='M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z'
            ></path>
          </svg>
        ),
      },
      {
        name: t('footer.social.twitter'),
        href: 'https://twitter.com/trazable_io',
        icon: (props: SVGProps<SVGSVGElement>) => (
          <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
            <path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
          </svg>
        ),
      },
      {
        name: t('footer.social.instagram'),
        href: 'https://www.instagram.com/trazable.io/',
        icon: (props: SVGProps<SVGSVGElement>) => (
          <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
            <path
              fillRule='evenodd'
              d='M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z'
              clipRule='evenodd'
            />
          </svg>
        ),
      },
    ],
    [t]
  )

  const solutions = useMemo(
    () => [
      {
        name: t('nsLayout:footer.sectionSolutions.traceability'),
        to: PAGES.SOLUTIONS.TRACEABILITY,
      },
      {
        name: t('nsLayout:footer.sectionSolutions.digitalTransformation'),
        to: PAGES.SOLUTIONS.DIGITAL_TRANSFORMATION,
      },
      {
        name: t('nsLayout:footer.sectionSolutions.transparency'),
        to: PAGES.SOLUTIONS.TRANSPARENCY,
      },
    ],
    [t]
  )

  return (
    <footer
      className='bg-gray-50'
      aria-labelledby='footer-heading'
      style={{
        ...(dark
          ? {
              background: 'linear-gradient(0deg, #090214, #0F0321)',
              // transform: 'matrix(1, 0, 0, -1, 0, 0)',
            }
          : {}),
      }}
    >
      <h2 id='footer-heading' className='sr-only'>
        Footer
      </h2>
      <div className='max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
        <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
          <div className='space-y-8 xl:col-span-1'>
            <img className='h-5' src={dark ? logoDark : logo} alt='Trazable' />
            <p
              className={classNames('text-base', { 'text-gray-500': !dark, 'text-gray-200': dark })}
            >
              {t('footer.title')}
            </p>
            <div className='flex space-x-6'>
              {social.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames({
                    'text-gray-400 hover:text-gray-500': !dark,
                    'text-gray-200 hover:text-white': dark,
                  })}
                  target='_blank'
                  rel='noreferrer'
                >
                  <span className='sr-only'>{item.name}</span>
                  <item.icon className='h-6 w-6' aria-hidden='true' />
                </a>
              ))}
            </div>
            <Menu as='div' className='inline-block md:hidden relative text-left'>
              <div>
                <Menu.Button
                  className={classNames('rounded-full flex items-center', {
                    'text-gray-500 hover:text-gray-900': !dark,
                    'text-gray-200 hover:text-white': dark,
                  })}
                >
                  <span className='sr-only'>Open options</span>
                  <GlobeIcon className='h-6 w-6' aria-hidden='true' />
                  <ChevronDownIcon className='ml-2 h-5 w-5' aria-hidden='true' />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                  <div className='py-1'>
                    {languages.map(([lngCode, lngName]) => (
                      <Menu.Item key={lngCode}>
                        {({ active }) => (
                          <button
                            onClick={() => changePathLanguage(window.location.pathname, lngCode)}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm w-full text-left'
                            )}
                          >
                            {lngName}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className='mt-12 grid grid-cols-1 gap-8 xl:mt-0 xl:col-span-2'>
            <div className='md:grid md:grid-cols-3 md:gap-8'>
              <div>
                <h3
                  className={classNames('text-sm font-semibold tracking-wider uppercase', {
                    'text-gray-400': !dark,
                    'text-gray-200': dark,
                  })}
                >
                  {t('footer.sectionSolutions.title')}
                </h3>
                <ul className='mt-4 space-y-4'>
                  {solutions.map(solution => (
                    <li key={solution.name}>
                      <a
                        href={`${Config.WEBSITE_URL}${getTranslatedPath(
                          location.pathname,
                          solution.to
                        )}`}
                        className={classNames('text-base', {
                          'text-gray-500 hover:text-gray-900': !dark,
                          'text-gray-300 hover:text-white': dark,
                        })}
                      >
                        {solution.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='mt-12 md:mt-0'>
                <h3
                  className={classNames('text-sm font-semibold tracking-wider uppercase', {
                    'text-gray-400': !dark,
                    'text-gray-200': dark,
                  })}
                >
                  {t('footer.sectionCompany.title')}
                </h3>
                <ul className='mt-4 space-y-4'>
                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${getTranslatedPath(
                        location.pathname,
                        PAGES.ABOUT
                      )}`}
                      className={classNames('text-base', {
                        'text-gray-500 hover:text-gray-900': !dark,
                        'text-gray-200 hover:text-white': dark,
                      })}
                    >
                      {t('footer.sectionCompany.about')}
                    </a>
                  </li>

                  <li>
                    <a
                      href={PAGES.EXTERNAL.BLOG}
                      className={classNames('text-base', {
                        'text-gray-500 hover:text-gray-900': !dark,
                        'text-gray-200 hover:text-white': dark,
                      })}
                    >
                      {t('footer.sectionCompany.blog')}
                    </a>
                  </li>

                  <li>
                    <a
                      href={PAGES.EXTERNAL.HIRE}
                      target='_blank'
                      className={classNames('text-base', {
                        'text-gray-500 hover:text-gray-900': !dark,
                        'text-gray-200 hover:text-white': dark,
                      })}
                      rel='noreferrer'
                    >
                      {t('footer.sectionCompany.hire')}
                    </a>
                  </li>

                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${getTranslatedPath(
                        location.pathname,
                        PAGES.SUPPORTS
                      )}`}
                      className={classNames('text-base', {
                        'text-gray-500 hover:text-gray-900': !dark,
                        'text-gray-200 hover:text-white': dark,
                      })}
                    >
                      {t('footer.sectionCompany.supports')}
                    </a>
                  </li>

                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${getTranslatedPath(
                        location.pathname,
                        PAGES.PRICING
                      )}`}
                      className={classNames('text-base', {
                        'text-gray-500 hover:text-gray-900': !dark,
                        'text-gray-200 hover:text-white': dark,
                      })}
                    >
                      {t('footer.sectionCompany.pricing')}
                    </a>
                  </li>
                </ul>
              </div>
              <div className='mt-12 md:mt-0'>
                <h3
                  className={classNames('text-sm font-semibold tracking-wider uppercase', {
                    'text-gray-400': !dark,
                    'text-gray-200': dark,
                  })}
                >
                  {t('footer.sectionLegal.title')}
                </h3>
                <ul className='mt-4 space-y-4'>
                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${getTranslatedPath(
                        location.pathname,
                        PAGES.PRIVACY_POLICY
                      )}`}
                      className={classNames('text-base', {
                        'text-gray-500 hover:text-gray-900': !dark,
                        'text-gray-200 hover:text-white': dark,
                      })}
                    >
                      {t('footer.sectionLegal.privacyPolicy')}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${getTranslatedPath(
                        location.pathname,
                        PAGES.COOKIES
                      )}`}
                      className={classNames('text-base', {
                        'text-gray-500 hover:text-gray-900': !dark,
                        'text-gray-200 hover:text-white': dark,
                      })}
                    >
                      {t('footer.sectionLegal.cookiesPolicy')}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${getTranslatedPath(
                        location.pathname,
                        PAGES.LEGAL_NOTICE
                      )}`}
                      className={classNames('text-base', {
                        'text-gray-500 hover:text-gray-900': !dark,
                        'text-gray-200 hover:text-white': dark,
                      })}
                    >
                      {t('footer.sectionLegal.legalNotice')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-12 border-t border-gray-200 pt-8'>
          <p className='text-base text-gray-400 xl:text-center'>&copy; {t('footer.copyright')}</p>
        </div>
      </div>
    </footer>
  )
}
